import { TFunction } from 'i18next';
import {
  ProjectExistingMarketingEvaluationReportV1Details,
  ProjectExistingMarketingEvaluationReportV2Details,
  ProjectExistingMarketingEvaluationReportV3Details,
  ProjectExistingMarketingEvaluationReportV4Details,
} from '@mayple/types';

import getProjectExistingMarketingEvaluationReportV1DetailsQuestions from './getProjectExistingMarketingEvaluationReportV1DetailsQuestions';
import getProjectExistingMarketingEvaluationReportV2DetailsQuestions from './getProjectExistingMarketingEvaluationReportV2DetailsQuestions';
import getProjectExistingMarketingEvaluationReportV3DetailsQuestions from './getProjectExistingMarketingEvaluationReportV3DetailsQuestions';
import getProjectExistingMarketingEvaluationReportV4DetailsQuestions from './getProjectExistingMarketingEvaluationReportV4DetailsQuestions';

import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';

export const PROPS_GROUPS = {
  SCORE_QUESTIONS: 'Score questions',
  YES_NO_QUESTIONS: 'Yes No questions',
  OPEN_QUESTIONS: 'Open questions',
};

const translateQuestion = (
  t: TFunction,
  reportDetailsType: string | undefined,
  propertiesMetaType: PropertiesMetaType,
): PropertiesMetaType => ({
  ...propertiesMetaType,
  label: t(
    `${reportDetailsType}.questions.${propertiesMetaType.label}`,
    t(`questions.${propertiesMetaType.label}`, propertiesMetaType.label),
  ),
});

type ReportDetailsType =
  | 'ProjectExistingMarketingEvaluationReportV1Details'
  | 'ProjectExistingMarketingEvaluationReportV2Details'
  | 'ProjectExistingMarketingEvaluationReportV3Details'
  | 'ProjectExistingMarketingEvaluationReportV4Details';

const marketerFeedbackReportQuestionsMapper: Record<ReportDetailsType, () => PropertiesMetaType[]> = {
  ProjectExistingMarketingEvaluationReportV1Details: getProjectExistingMarketingEvaluationReportV1DetailsQuestions,
  ProjectExistingMarketingEvaluationReportV2Details: getProjectExistingMarketingEvaluationReportV2DetailsQuestions,
  ProjectExistingMarketingEvaluationReportV3Details: getProjectExistingMarketingEvaluationReportV3DetailsQuestions,
  ProjectExistingMarketingEvaluationReportV4Details: getProjectExistingMarketingEvaluationReportV4DetailsQuestions,
};

export const getMarketerFeedbackReportQuestions = (
  t: TFunction,
  reportDetails:
    | null
    | undefined
    | ProjectExistingMarketingEvaluationReportV1Details
    | ProjectExistingMarketingEvaluationReportV2Details
    | ProjectExistingMarketingEvaluationReportV3Details
    | ProjectExistingMarketingEvaluationReportV4Details,
): PropertiesMetaType[] => {
  if (!reportDetails) {
    return [];
  }

  // eslint-disable-next-line no-underscore-dangle
  const reportDetailsType = reportDetails.__typename;

  const getQuestions = reportDetailsType ? marketerFeedbackReportQuestionsMapper?.[reportDetailsType] : null;

  const questions = getQuestions?.();

  return (questions ?? []).map((question: PropertiesMetaType) => translateQuestion(t, reportDetailsType, question));
};
