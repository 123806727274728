import {
  AdSpend,
  EmployeeCount,
  MarketerGraduationPlan,
  MarketingServiceType,
  ProjectSpecialType,
  DeliverablesFulfillmentBehavior,
  ProjectSpecialSubType,
  ProjectCycleOperationMode,
  ProjectPackageState,
  ProjectPackageDelayReason,
  PayoutType,
  AccountManager,
} from '@mayple/types';
import keyBy from 'lodash/keyBy';
import { iconCodes } from './icons';
import { svgs } from './svg';
import { colors } from './theme';
import {
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  AUTO_SUGGEST_SKILLS,
  REMOTE_WORK,
  PAYMENT_METHODS,
} from './consts';

export function pairsToLabelLookup(pairs) {
  const labelsLookup = {};
  for (const pair of pairs) {
    const { value, label, svgIcon, fontIcon, tooltip } = pair;
    labelsLookup[value] = label;

    if (svgIcon) {
      labelsLookup[`${value}-svgIcon`] = svgIcon;
    }
    if (fontIcon) {
      labelsLookup[`${value}-fontIcon`] = fontIcon;
    }
    if (tooltip) {
      labelsLookup[`${value}-tooltip`] = tooltip;
    }
  }
  return labelsLookup;
}

export const createPairsFromKeyValueObject = (obj = {}) =>
  Object.entries(obj).map(([value, label]) => ({ label, value }));

/**
 * Same as pairsToLabelLookup, but for grouped enums that are created by function createGroupedEnum().
 * @param groups
 * @param groupsLabels
 * @returns {{}}
 */
function groupedPairsToLabelLookup(groups, groupsLabels) {
  let labels = {};

  for (const group of groups) {
    const { options } = group;
    labels = { ...labels, ...pairsToLabelLookup(options) };
  }

  for (const [value, label] of Object.entries(groupsLabels)) {
    labels[value] = label;
  }

  return labels;
}

function pairsToValueLookup(pairs) {
  const labelsLookup = {};
  for (const pair of pairs) {
    labelsLookup[pair.label] = pair.value;
  }
  return labelsLookup;
}

function pairsToLabelsList(pairs) {
  return pairs.map(({ label }) => label);
}

/**
 * Sorts enums by A-Z
 * @returns {function(*, *): number}
 */
export function sortByLabel(attr = 'label') {
  return (a, b) => {
    if (a[attr] < b[attr]) {
      return -1;
    }
    if (a[attr] > b[attr]) {
      return 1;
    }
    return 0;
  };
}

/**
 * Will create an enums that can be used for selecting values part of a category group.
 * For example: { label: 'Some label.', value: 'someValue', category: 'categoryXyz' } is a single enum option.
 * @param categoriesLabels
 * @param subCategories
 * @param deprecatedSubCategories
 * @param categoryKey
 * @returns {[]}
 */
function createGroupedEnum(categoriesLabels, subCategories, deprecatedSubCategories, categoryKey = 'categoryName') {
  const enums = [];

  for (const cKey of Object.keys(categoriesLabels)) {
    const category = Object.entries(subCategories[cKey]);
    const options = [];

    for (const [key, value] of category) {
      options.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        deprecated: deprecatedSubCategories.has(key),
      });
    }

    enums.push({
      label: categoriesLabels[cKey],
      category: cKey,
      value: cKey,
      [categoryKey]: cKey,
      options,
    });
  }

  return enums;
}

function createCategorizedEnum(categoriesLabels, subCategories, deprecatedSubCategories, categoryKey = 'categoryName') {
  const enums = [];

  for (const [cKey, cLabel] of Object.entries(categoriesLabels)) {
    const category = Object.entries(subCategories[cKey]);

    for (const [key, value] of category) {
      enums.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        categoryLabel: cLabel,
        deprecated: deprecatedSubCategories.has(key),
      });
    }
  }

  return enums;
}

function createFlatCategoriesEnum(
  categoriesLabels,
  subCategories,
  deprecatedSubCategories,
  categoryKey = 'categoryName',
) {
  const enums = [];

  for (const cKey of Object.keys(categoriesLabels)) {
    const category = Object.entries(subCategories[cKey]);

    enums.push({
      label: categoriesLabels[cKey],
      category: cKey,
      [categoryKey]: cKey,
    });

    for (const [key, value] of category) {
      enums.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        deprecated: deprecatedSubCategories.has(cKey),
      });
    }
  }

  return enums;
}

export const EMPLOYEE_COUNT_PAIRS = [
  { label: 'Just 1', value: EmployeeCount.FROM_1_TO_1 },
  { label: 'From 2 to 5', value: EmployeeCount.FROM_2_TO_5 },
  { label: 'From 6 to 10', value: EmployeeCount.FROM_6_TO_10 },
  { label: 'From 11 to 25', value: EmployeeCount.FROM_11_TO_25 },
  { label: 'From 26 to 49', value: EmployeeCount.FROM_26_TO_49 },
  { label: 'From 50 to 199', value: EmployeeCount.FROM_50_TO_199 },
  { label: 'From 200 to 499', value: EmployeeCount.FROM_200_TO_499 },
  { label: '500 or more', value: EmployeeCount.FROM_500_OR_MORE },
];
export const EMPLOYEE_COUNT_LABELS_LOOKUP = pairsToLabelLookup(EMPLOYEE_COUNT_PAIRS);

export const AD_SPEND_PAIRS = [
  { label: '$0 - $2K', value: AdSpend.FROM_0_TO_2000, estimatedMediaBudget: 1250, min: 0, max: 2000 },
  { label: '$2K - $5K', value: AdSpend.FROM_2001_TO_5000, estimatedMediaBudget: 3750, min: 2001, max: 5000 },
  { label: '$5K - $10K', value: AdSpend.FROM_5001_TO_10000, estimatedMediaBudget: 7500, min: 5001, max: 10000 },
  { label: '$10K - $20K', value: AdSpend.FROM_10001_TO_20000, estimatedMediaBudget: 15000, min: 10001, max: 20000 },
  { label: '$20K - $50K', value: AdSpend.FROM_20001_TO_50000, estimatedMediaBudget: 35000, min: 20001, max: 50000 },
  { label: '$50K - $100K', value: AdSpend.FROM_50001_TO_100000, estimatedMediaBudget: 75000, min: 50001, max: 100000 },
  {
    label: '$100K - $200K',
    value: AdSpend.FROM_100001_TO_200000,
    estimatedMediaBudget: 150000,
    min: 100000,
    max: 200000,
  },
  {
    label: '$200K - $500K',
    value: AdSpend.FROM_200001_TO_500000,
    estimatedMediaBudget: 350000,
    min: 200000,
    max: 500000,
  },
  {
    label: '$500K - $1M',
    value: AdSpend.FROM_500001_TO_1000000,
    estimatedMediaBudget: 750000,
    min: 500000,
    max: 1000000,
  },
  { label: '$1M+', value: AdSpend.FROM_1000001_OR_MORE, estimatedMediaBudget: 2000000, min: 1000000, max: 10000000 },
];
export const AD_SPEND_LABELS_LOOKUP = pairsToLabelLookup(AD_SPEND_PAIRS);

export const COMPANY_AD_SPEND_PAIRS = [
  {
    label: '$7K - $10K',
    subtitle: 'per month',
    value: 'FROM_7000_TO_10000',
    estimatedMediaBudget: 8500,
    min: 7000,
    max: 10000,
  },
  {
    label: '$10K - $15K',
    subtitle: 'per month',
    value: 'FROM_10001_TO_15000',
    estimatedMediaBudget: 12500,
    min: 2001,
    max: 5000,
  },
  {
    label: '$15K - $20K',
    subtitle: 'per month',
    value: 'FROM_15001_TO_20000',
    estimatedMediaBudget: 17500,
    min: 5001,
    max: 10000,
  },
  {
    label: '$20K - $50K',
    subtitle: 'per month',
    value: 'FROM_20001_TO_50000',
    estimatedMediaBudget: 35000,
    min: 20001,
    max: 50000,
  },
  {
    label: '$50K - $100K',
    subtitle: 'per month',
    value: 'FROM_50001_TO_100000',
    estimatedMediaBudget: 75000,
    min: 10001,
    max: 100000,
  },
  {
    label: '$100K+',
    subtitle: 'per month',
    value: 'FROM_100001_OR_MORE',
    estimatedMediaBudget: 150000,
    min: 100001,
    max: 150000,
  },
];
export const COMPANY_AD_SPEND_LABELS_LOOKUP = pairsToLabelLookup(COMPANY_AD_SPEND_PAIRS);

/*
 // TODO: DEV-6663 - remove
 export const GENDERS_PAIRS = [
  { label: 'Male', value: 'GENDER_MALE' },
  { label: 'Female', value: 'GENDER_FEMALE' },
  { label: 'All', value: 'GENDER_ALL', isDeprecated: true, deprecated: true },
  { label: 'Other', value: 'GENDER_OTHER' },
];
export const GENDERS_LABELS_LOOKUP = pairsToLabelLookup(GENDERS_PAIRS);
 */

// TODO: DEV-6663 - remove
/*
 export const AGE_RANGES_PAIRS = [
  { label: 'Under 14', value: MarketingLimitationAgeRange.AGE_RANGE_2_13 },
  { label: '14-18', value: MarketingLimitationAgeRange.AGE_RANGE_14_18 },
  { label: '19-25', value: MarketingLimitationAgeRange.AGE_RANGE_19_25 },
  { label: '26-39', value: MarketingLimitationAgeRange.AGE_RANGE_26_39 },
  { label: '40-55', value: MarketingLimitationAgeRange.AGE_RANGE_40_55 },
  { label: '56 and older', value: MarketingLimitationAgeRange.AGE_RANGE_56_UP },
  { label: 'Undetermined', value: MarketingLimitationAgeRange.AGE_RANGE_UNDETERMINED, deprecated: true },
];
 // export const AGE_RANGES_LABELS_LOOKUP = pairsToLabelLookup(AGE_RANGES_PAIRS);
*/

export const PLATFORMS_PAIRS = [
  { label: 'Computers', value: 'PLATFORM_Desktop' },
  { label: 'Mobile devices with full browsers', value: 'PLATFORM_HighEndMobile' },
  { label: 'Tablets with full browsers', value: 'PLATFORM_Tablet' },
];
export const PLATFORMS_LABELS_LOOKUP = pairsToLabelLookup(PLATFORMS_PAIRS);

export const LANGUAGES_PAIRS = [
  { label: 'English', value: 'LANGUAGE_en' },
  { label: 'Hebrew', value: 'LANGUAGE_iw' },
  { label: 'French', value: 'LANGUAGE_fr' },
  { label: 'Arabic', value: 'LANGUAGE_ar' },
  { label: 'Bulgarian', value: 'LANGUAGE_bg' },
  { label: 'Catalan', value: 'LANGUAGE_ca' },
  { label: 'Chinese (simplified)', value: 'LANGUAGE_zh_CN' },
  { label: 'Chinese (traditional)', value: 'LANGUAGE_zh_TW' },
  { label: 'Croatian', value: 'LANGUAGE_hr' },
  { label: 'Czech', value: 'LANGUAGE_cs' },
  { label: 'Danish', value: 'LANGUAGE_da' },
  { label: 'Dutch', value: 'LANGUAGE_nl' },
  { label: 'Estonian', value: 'LANGUAGE_et' },
  { label: 'Filipino', value: 'LANGUAGE_tl' },
  { label: 'Finnish', value: 'LANGUAGE_fi' },
  { label: 'German', value: 'LANGUAGE_de' },
  { label: 'Greek', value: 'LANGUAGE_el' },
  { label: 'Hindi', value: 'LANGUAGE_hi' },
  { label: 'Hungarian', value: 'LANGUAGE_hu' },
  { label: 'Icelandic', value: 'LANGUAGE_is' },
  { label: 'Indonesian', value: 'LANGUAGE_id' },
  { label: 'Italian', value: 'LANGUAGE_it' },
  { label: 'Japanese', value: 'LANGUAGE_ja' },
  { label: 'Korean', value: 'LANGUAGE_ko' },
  { label: 'Latvian', value: 'LANGUAGE_lv' },
  { label: 'Lithuanian', value: 'LANGUAGE_lt' },
  { label: 'Malay', value: 'LANGUAGE_ms' },
  { label: 'Norwegian', value: 'LANGUAGE_no' },
  { label: 'Persian', value: 'LANGUAGE_fa' },
  { label: 'Polish', value: 'LANGUAGE_pl' },
  { label: 'Portuguese', value: 'LANGUAGE_pt' },
  { label: 'Romanian', value: 'LANGUAGE_ro' },
  { label: 'Russian', value: 'LANGUAGE_ru' },
  { label: 'Serbian', value: 'LANGUAGE_sr' },
  { label: 'Slovak', value: 'LANGUAGE_sk' },
  { label: 'Slovenian', value: 'LANGUAGE_sl' },
  { label: 'Spanish', value: 'LANGUAGE_es' },
  { label: 'Swedish', value: 'LANGUAGE_sv' },
  { label: 'Thai', value: 'LANGUAGE_th' },
  { label: 'Turkish', value: 'LANGUAGE_tr' },
  { label: 'Ukrainian', value: 'LANGUAGE_uk' },
  { label: 'Urdu', value: 'LANGUAGE_ur' },
  { label: 'Vietnamese', value: 'LANGUAGE_vi' },
];
export const LANGUAGES_LABELS_LOOKUP = pairsToLabelLookup(LANGUAGES_PAIRS);

/* Industries */
const industryCategoryKey = 'industry';

export const INDUSTRIES_GROUPS = createGroupedEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_PAIRS = createCategorizedEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_PAIRS_FLAT = createFlatCategoriesEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_LABELS_LOOKUP = groupedPairsToLabelLookup(INDUSTRIES_GROUPS, INDUSTRIES_CATEGORIES_LABELS);

export const TARGET_KPI_PAIRS = [
  { label: 'CPM (Cost Per 1000 Impressions)', value: 'CPM' },
  { label: 'CPL (Cost Per Lead)', value: 'CPL' },
  { label: 'CPI (Cost Per Installation)', value: 'CPI' },
  { label: 'CPR (Cost Per Registration)', value: 'CPR' },
  { label: 'CPO (Cost per Purchase)', value: 'CPO' },
  { label: 'ROAS (Return On Ad Spend)', value: 'ROAS' },
  // { label: 'CPA (Cost Per Acquisition)', value: 'CPA', isDeprecated: true },
  // { label: 'CPC (Cost Per Click)', value: 'CPC', isDeprecated: true },
  // { label: 'CTR (Click-Through Rate)', value: 'CTR', isDeprecated: true },
  // { label: 'Clicks', value: 'Clicks', isDeprecated: true },
  // { label: 'Conversions', value: 'Conversions', isDeprecated: true },
  // { label: 'Return On Investment (ROI)', value: 'ROI' },
];
export const TARGET_KPI_LABELS_LOOKUP = pairsToLabelLookup(TARGET_KPI_PAIRS);

export const TARGET_KPI_WITH_ICONS_PAIRS = [
  // { label: 'CPC (Cost per Click)', value: 'CPC', svgIcon: svgs.roas},
  // {
  //   label:       'Clicks',
  //   value:       'CPC',
  //   svgIcon:     svgs.roas,
  //   textOnHover: 'You are looking to improve the ratio between your online revenue and your  media cost',
  // },
  {
    label: 'Impressions',
    value: 'CPM',
    svgIcon: svgs.impressionsLight,
    textOnHover: 'You are interested in reaching as many relevant people as possible',
  },
  {
    label: 'Leads',
    value: 'CPL',
    svgIcon: svgs.leadsLight,
    textOnHover: 'You are looking for qualified leads in order to contact them by phone or by mail',
  },
  {
    label: 'Registrations',
    value: 'CPR',
    svgIcon: svgs.registrationsLight,
    textOnHover: 'You want new users to register into your platform or customers loyalty program',
  },
  {
    label: 'Installs',
    value: 'CPI',
    svgIcon: svgs.installsLight,
    textOnHover: 'You want more quality installs for your app',
  },
  {
    label: 'Purchases',
    value: 'CPO',
    svgIcon: svgs.purchasesLight,
    textOnHover: 'You want more purchases in your website',
  },
  {
    label: 'ROAS',
    value: 'ROAS',
    svgIcon: svgs.roasLight,
    textOnHover: 'You want to improve the ratio between your online revenue and ad spend',
  },
];
export const TARGET_KPI_WITH_ICONS_PAIRS_MARKETER = [
  {
    label: 'Branding Campaigns',
    value: 'CPM',
    svgIcon: svgs.eyeBlue,
  },
  {
    label: 'Lead Campaigns',
    value: 'CPL',
    svgIcon: svgs.bubbleChatBlue,
  },
  {
    label: 'Registration Campaigns',
    value: 'CPR',
    svgIcon: svgs.accountBlue,
  },
  {
    label: 'App Install Campaigns',
    value: 'CPI',
    svgIcon: svgs.downloadBlue,
  },
  {
    label: 'Acquisition Campaigns',
    value: 'CPO',
    svgIcon: svgs.loveBlue,
  },
  {
    label: 'eCommerce Campaigns',
    value: 'ROAS',
    svgIcon: svgs.graphBlue,
  },
];
export const TARGET_KPI_WITH_ICONS_LABELS_LOOKUP = pairsToLabelLookup(TARGET_KPI_WITH_ICONS_PAIRS);
export const TARGET_KPI_WITH_ICONS_MARKETER_LABELS_LOOKUP = pairsToLabelLookup(TARGET_KPI_WITH_ICONS_PAIRS_MARKETER);

export const COUNTRIES_PAIRS = [
  { label: 'United States', value: 'US' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Israel', value: 'IL' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Canada', value: 'CA' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo, Republic of the (Brazzaville)', value: 'CG' },
  { label: 'Congo, the Democratic Republic of the (Kinshasa)', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Côte d'Ivoire, Republic of", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Islas Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern and Antarctic Lands', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia, The', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Great Britain', value: 'GB' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Laos', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela, Bolivarian Republic of', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const TARGETED_COUNTRIES_PAIRS = [...COUNTRIES_PAIRS];
// OLDER VERSION ^^^
// export const TARGETED_COUNTRIES_PAIRS = [
//   { label: 'Worldwide', value: 'WW' },
//   ...COUNTRIES_PAIRS,
// ];

// To allow the option to enter great britain as input, but we want to display United kingdom as view.
const filteredGreatBritainList = COUNTRIES_PAIRS.filter(({ label }) => label !== 'Great Britain');

export const COUNTRIES_LABELS_LOOKUP = pairsToLabelLookup(filteredGreatBritainList);
export const COUNTRIES_VALUES_LOOKUP = pairsToValueLookup(COUNTRIES_PAIRS);
export const TARGETED_COUNTRIES_LABELS_LOOKUP = pairsToLabelLookup(TARGETED_COUNTRIES_PAIRS);
// export const TARGETED_COUNTRIES_VALUES_LOOKUP = pairsToValueLookup(TARGETED_COUNTRIES_PAIRS);

export const STATES_PAIRS = [
  { label: 'Armed Forces Americas', value: 'AA' },
  { label: 'Alberta', value: 'AB' },
  { label: 'Armed Forces (AE)', value: 'AE' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Armed Forces Pacific', value: 'AP' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Maine', value: 'ME' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Montana', value: 'MT' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New York', value: 'NY' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Virgina', value: 'VA' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'Yukon Territory;', value: 'YT' },
];
export const STATES_LABELS_LOOKUP = pairsToLabelLookup(STATES_PAIRS);

export const CURRENCY_TYPE_PAIRS = [
  { label: 'U.S. Dollar', value: 'USD' },
  { label: 'Israeli Shekel', value: 'ILS' },
];
export const CURRENCY_TYPE_LABELS_LOOKUP = pairsToLabelLookup(CURRENCY_TYPE_PAIRS);

export const FUND_ACCOUNT_STATUS_PAIRS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Suspended', value: 'SUSPENDED' },
  { label: 'Closed', value: 'CLOSED' },
];
export const FUND_ACCOUNT_STATUS_LABELS_LOOKUP = pairsToLabelLookup(FUND_ACCOUNT_STATUS_PAIRS);

export const DEPOSIT_SOURCE_PAIRS = [
  { label: 'Funds Grant', value: 'GRANT' },
  { label: 'Funds Redeposit', value: 'REDEPOSIT' },
  { label: 'Paypal Payment', value: 'PAYPAL' },
  { label: 'Credit Card Payment', value: 'CREDIT_CARD' },
  { label: 'Cash Payment', value: 'CASH' },
  { label: 'Wire Transfer', value: 'WIRE_TRANSFER' },
];
// export const DEPOSIT_SOURCE_LABELS_LOOKUP = pairsToLabelLookup(DEPOSIT_SOURCE_PAIRS);

export const WITHDRAWAL_TARGET_PAIRS = [
  { label: 'General Payment', value: 'GENERAL' },
  { label: 'Service Fee', value: 'SERVICE_FEE' },
  { label: 'Marketer Fee', value: 'MARKETER_FEE' },
  { label: 'Facebook Ads spend', value: 'FACEBOOK_MARKETING' },
  { label: 'Google AdWords spend', value: 'GOOGLE_ADWORDS' },
];
// export const WITHDRAWAL_TARGET_LABELS_LOOKUP = pairsToLabelLookup(WITHDRAWAL_TARGET_PAIRS);

export const AUDIT_RECORD_OPERATION_CATEGORY_PAIRS = [
  { label: 'Created Acocunt', value: 'CREATE_ACCOUNT' },
  { label: 'Closed Account', value: 'CLOSE_ACCOUNT' },
  { label: 'Suspended Account', value: 'SUSPEND_ACCOUNT' },
  { label: 'Unsuspended Account', value: 'UNSUSPEND_ACCOUNT' },
  { label: 'Deposited Funds', value: 'DEPOSIT' },
  { label: 'Withdrawed Funds', value: 'WITHDRAWAL' },
  { label: 'Unknown Operation', value: 'UNKNOWN' },
  { label: 'Modified Account', value: 'MODIFY_ACCOUNT' },
];
// export const AUDIT_RECORD_OPERATION_CATEGORY_LABELS_LOOKUP =
// pairsToLabelLookup(AUDIT_RECORD_OPERATION_CATEGORY_PAIRS);

export const AD_PREVIEW_TYPE_PAIRS = [
  { label: 'No Type', value: 'AD_PREVIEW_TYPE_NONE' },
  { label: 'Facebook', value: 'AD_PREVIEW_TYPE_HTML' },
  { label: 'AdWords', value: 'AD_PREVIEW_TYPE_ADWORDS' },
];
// export const AD_PREVIEW_TYPE_LABELS_LOOKUP = pairsToLabelLookup(AD_PREVIEW_TYPE_PAIRS);

export const BUSINESS_MARKETING_EXPECTATIONS_PAIRS = [
  { value: 'INCREASE_SALES', label: 'Increase Sales' },
  { value: 'INCREASE_PROFIT', label: 'Increase Profit' },
  { value: 'BUILD_BRAND_AWARENESS', label: 'Build Brand Awareness' },
  { value: 'GROW_MARKET_SHARE', label: 'Grow Market Share' },
  { value: 'LAUNCH_NEW_PRODUCTS_OR_SERVICES', label: 'Launch New Products or Services' },
  { value: 'TARGET_NEW_CUSTOMERS', label: 'Target New Customers' },
  { value: 'ENTER_NEW_MARKETS_INTERNATIONALLY', label: 'Enter New Markets Internationally' },
  { value: 'ENTER_NEW_MARKETS_LOCALLY', label: 'Enter New Markets Locally' },
  { value: 'IMPROVE_STAKEHOLDER_RELATIONS', label: 'Improve Stakeholder Relations' },
  { value: 'ENHANCE_CUSTOMER_RELATIONSHIPS', label: 'Enhance Customer Relationships' },
  { value: 'IMPROVE_INTERNAL_COMMUNICATIONS', label: 'Improve Internal Communications' },
];
export const BUSINESS_MARKETING_EXPECTATIONS_LABELS_LOOKUP = pairsToLabelLookup(BUSINESS_MARKETING_EXPECTATIONS_PAIRS);

export const MARKETER_TYPE_PAIRS = [
  { label: 'Freelancer', value: 'FREELANCER' },
  { label: 'Digital Agency', value: 'DIGITAL_AGENCY' },
];
export const MARKETER_TYPE_LABELS_LOOKUP = pairsToLabelLookup(MARKETER_TYPE_PAIRS);

export const MARKETING_SERVICES_PAIRS_WITH_ICONS = [
  {
    label: 'Affiliate Marketing',
    value: MarketingServiceType.AFFILIATE_MARKETING,
    svgIcon: svgs.affiliateMarketingLight,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Branding',
    value: MarketingServiceType.BRANDING,
    svgIcon: svgs.brandingRegular,
    fontIcon: iconCodes.fingerprint,
  },
  {
    label: 'Chatbot marketing',
    value: MarketingServiceType.CHATBOT_MARKETING,
    svgIcon: svgs.chatbotMarketingRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'CMO as a Service',
    value: MarketingServiceType.CMO,
    svgIcon: svgs.cmoRegular,
    fontIcon: iconCodes.supervisor,
  },
  {
    label: 'Community Management',
    value: MarketingServiceType.COMMUNITY_MANAGEMENT,
    svgIcon: svgs.communityManagementRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Content',
    value: MarketingServiceType.CONTENT,
    svgIcon: svgs.contentRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Copywriting',
    value: MarketingServiceType.COPYWRITING,
    svgIcon: svgs.copywritingRegular,
    fontIcon: iconCodes.copyWriting,
  },
  {
    label: 'CRM implementation',
    value: MarketingServiceType.CRM_IMPLEMENTATION,
    svgIcon: svgs.crmImplementationRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'CRO (Conversion Rate Optimization)',
    value: MarketingServiceType.CRO,
    svgIcon: svgs.croRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Graphic Design',
    value: MarketingServiceType.DESIGN,
    svgIcon: svgs.graphicDesignRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Email Marketing',
    value: MarketingServiceType.EMAIL_MARKETING,
    svgIcon: svgs.emailMarketingRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Influencers Marketing',
    value: MarketingServiceType.INFLUENCERS_MARKETING,
    svgIcon: svgs.influencersMarketingRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Marketing Strategy',
    value: MarketingServiceType.MARKETING_STRATEGY,
    svgIcon: svgs.marketingStrategyRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Search Engine Optimization',
    value: MarketingServiceType.SEARCH_ENGINE_OPTIMIZATION,
    svgIcon: svgs.searchEngineOptimizationRegular,
    fontIcon: iconCodes.find_replace,
  },
  {
    label: 'SMS Marketing',
    value: MarketingServiceType.SMS_MARKETING,
    svgIcon: svgs.chat_bubble,
    fontIcon: iconCodes.sendMessage,
  },
  {
    label: 'App Store Optimization',
    value: MarketingServiceType.APP_STORE_OPTIMIZATION,
    svgIcon: svgs.searchEngineOptimizationRegular,
    fontIcon: iconCodes.find_replace,
  },
  {
    label: 'Social Media Management',
    value: MarketingServiceType.SOCIAL_MEDIA_MANAGEMENT,
    svgIcon: svgs.socialMediaManagementRegular,
    fontIcon: iconCodes.like,
  },
  {
    label: 'Tracking implementation',
    value: MarketingServiceType.TRACKING_IMPLEMENTATION,
    svgIcon: svgs.trackingImplementationRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Video',
    value: MarketingServiceType.VIDEO,
    svgIcon: svgs.videoRegular,
    fontIcon: iconCodes.video,
  },
  {
    label: 'Web Design',
    value: MarketingServiceType.WEB_DESIGN,
    svgIcon: svgs.webDesignRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Web Development',
    value: MarketingServiceType.WEB_DEVELOPMENT,
    svgIcon: svgs.webDevelopmentRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Public Relations',
    value: MarketingServiceType.PUBLIC_RELATIONS,
    svgIcon: svgs.publicRelationsRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Growth Hacking',
    value: MarketingServiceType.GROWTH_HACKING,
    svgIcon: svgs.growthHackingRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Data Onboarding',
    value: MarketingServiceType.DATA_ONBOARDING,
    svgIcon: svgs.dataOnboardingRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Prepaid Media',
    value: MarketingServiceType.PREPAID_MEDIA,
    svgIcon: svgs.prepaidMediaRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Amazon Marketing',
    value: MarketingServiceType.AMAZON_MARKETING,
    svgIcon: svgs.prepaidMediaRegular,
    fontIcon: iconCodes.brush,
  },
  { label: 'Gong', value: MarketingServiceType.SKILL_GONG },
  { label: 'Salesloft', value: MarketingServiceType.SKILL_SALESLOFT },
  { label: 'Mixpanel', value: MarketingServiceType.SKILL_MIXPANEL },
  { label: 'Agorapulse', value: MarketingServiceType.SKILL_AGORAPULSE },
  { label: 'Constant Contact', value: MarketingServiceType.SKILL_CONSTANT_CONTACT },
  { label: 'Triple Whale', value: MarketingServiceType.SKILL_TRIPLE_WHALE },
  { label: 'Hubspot', value: MarketingServiceType.SKILL_HUBSPOT },
  { label: '6sense', value: MarketingServiceType.SKILL_6SENSE },
  { label: 'Sprout Social', value: MarketingServiceType.SKILL_SPROUT_SOCIAL },
  { label: 'Attentive', value: MarketingServiceType.SKILL_ATTENTIVE },
  { label: 'Figma', value: MarketingServiceType.SKILL_FIGMA },
  { label: 'Canva', value: MarketingServiceType.SKILL_CANVA },
  { label: 'Klaviyo', value: MarketingServiceType.SKILL_KLAVIYO },
  { label: 'Active Campaign', value: MarketingServiceType.SKILL_ACTIVE_CAMPAIGN },
  { label: 'Sendgrid', value: MarketingServiceType.SKILL_SENDGRID },
  { label: 'Marketo', value: MarketingServiceType.SKILL_MARKETO },
  { label: 'Photoshop', value: MarketingServiceType.SKILL_PHOTOSHOP },
  { label: 'Typeform', value: MarketingServiceType.SKILL_TYPEFORM },
  { label: 'Hootsuite', value: MarketingServiceType.SKILL_HOOTSUITE },
  { label: 'Looker', value: MarketingServiceType.SKILL_LOOKER },
  { label: 'Tableau', value: MarketingServiceType.SKILL_TABLEAU },
  { label: 'Salesforce', value: MarketingServiceType.SKILL_SALESFORCE },
  { label: 'Pipedrive', value: MarketingServiceType.SKILL_PIPEDRIVE },
  { label: 'Zoho', value: MarketingServiceType.SKILL_ZOHO },
  { label: 'Wix', value: MarketingServiceType.SKILL_WIX },
  { label: 'Wordpress', value: MarketingServiceType.SKILL_WORDPRESS },
  { label: 'Squarespace', value: MarketingServiceType.SKILL_SQUARESPACE },
  { label: 'Weebly', value: MarketingServiceType.SKILL_WEEBLY },
  { label: 'Supermetrics', value: MarketingServiceType.SKILL_SUPERMETRICS },
  { label: 'Northbeam', value: MarketingServiceType.SKILL_NORTHBEAM },
  { label: 'Hyros', value: MarketingServiceType.SKILL_HYROS },
  { label: 'Webflow', value: MarketingServiceType.SKILL_WEBFLOW },
  { label: 'Zapier', value: MarketingServiceType.SKILL_ZAPIER },
  { label: 'Make', value: MarketingServiceType.SKILL_MAKE },
  { label: 'Riverside', value: MarketingServiceType.SKILL_RIVERSIDE },
  { label: 'Yotpo', value: MarketingServiceType.SKILL_YOTPO },
  { label: 'Miro', value: MarketingServiceType.SKILL_MIRO },
  { label: 'Asana', value: MarketingServiceType.SKILL_ASANA },
  { label: 'Optimizely', value: MarketingServiceType.SKILL_OPTIMIZELY },
  { label: 'Monday', value: MarketingServiceType.SKILL_MONDAY },
  { label: 'Zendesk', value: MarketingServiceType.SKILL_ZENDESK },
  { label: 'Power BI', value: MarketingServiceType.SKILL_POWER_BI },
  { label: 'Sisense', value: MarketingServiceType.SKILL_SISENSE },
  { label: 'Bigquery', value: MarketingServiceType.SKILL_BIGQUERY },
  { label: 'Python', value: MarketingServiceType.SKILL_PYTHON },
  { label: 'SQL', value: MarketingServiceType.SKILL_SQL },
  { label: 'Mailchimp', value: MarketingServiceType.SKILL_MAILCHIMP },
  {
    label: 'Custom',
    value: MarketingServiceType.CUSTOM,
    svgIcon: svgs.otherMediaRegular,
    fontIcon: iconCodes.brush,
  },
  {
    label: 'Other',
    value: MarketingServiceType.OTHER,
    svgIcon: svgs.otherMediaRegular,
    fontIcon: iconCodes.brush,
  },
];
export const MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP = pairsToLabelLookup(
  MARKETING_SERVICES_PAIRS_WITH_ICONS,
);

const MARKETING_CHANNELS_GROUPS = {
  SOCIAL_NETWORKS: 'SOCIAL_NETWORKS',
  SEARCH: 'SEARCH',
  SHOPPING: 'SHOPPING',
  CONTENT: 'CONTENT',
};

export const MARKETING_CHANNELS_GROUPS_PAIRS = [
  { value: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS, label: 'Social networks', priority: 0 },
  { value: MARKETING_CHANNELS_GROUPS.SEARCH, label: 'Search', priority: 1 },
  { value: MARKETING_CHANNELS_GROUPS.SHOPPING, label: 'Shopping', priority: 2 },
  { value: MARKETING_CHANNELS_GROUPS.CONTENT, label: 'Content', priority: 3 },
];

export const MARKETING_CHANNELS_GROUPS_LOOKUP = keyBy(MARKETING_CHANNELS_GROUPS_PAIRS, 'value');

export const MARKETING_CHANNELS_PAIRS_WITH_ICONS = [
  { label: 'AdRoll', value: MarketingServiceType.ADROLL, svgIcon: svgs.adroll },
  {
    label: 'Amazon Advertising',
    value: MarketingServiceType.AMAZON_PPC,
    svgIcon: svgs.amazon,
    group: MARKETING_CHANNELS_GROUPS.SHOPPING,
    priority: 1,
  },
  {
    label: 'Bing Ads',
    value: MarketingServiceType.BING_ADS,
    svgIcon: svgs.bing,
    group: MARKETING_CHANNELS_GROUPS.SEARCH,
    priority: 1,
  },
  { label: 'Demand Side Platform (DSP)', value: MarketingServiceType.DEMAND_SIDE_PLATFORM, svgIcon: svgs.dspRegular },
  {
    label: 'Facebook Ads',
    value: MarketingServiceType.FACEBOOK_ADS,
    svgIcon: svgs.facebook,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 0,
  },
  {
    label: 'Google Ads',
    value: MarketingServiceType.GOOGLE_ADS,
    svgIcon: svgs.googleAds,
    group: MARKETING_CHANNELS_GROUPS.SEARCH,
    priority: 0,
  },
  {
    label: 'Google Shopping',
    value: MarketingServiceType.GOOGLE_SHOPPING,
    svgIcon: svgs.googleShopping,
    group: MARKETING_CHANNELS_GROUPS.SHOPPING,
    priority: 0,
  },
  {
    label: 'Instagram Ads',
    value: MarketingServiceType.INSTAGRAM_ADS,
    svgIcon: svgs.instagram,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 1,
  },
  {
    label: 'LinkedIn Ads',
    value: MarketingServiceType.LINKEDIN_ADS,
    svgIcon: svgs.linkedin,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 3,
  },
  {
    label: 'Outbrain',
    value: MarketingServiceType.OUTBRAIN,
    svgIcon: svgs.outbrain,
    group: MARKETING_CHANNELS_GROUPS.CONTENT,
    priority: 2,
  },
  {
    label: 'Pinterest Ads',
    value: MarketingServiceType.PINTEREST_ADS,
    svgIcon: svgs.pinterest,
    group: MARKETING_CHANNELS_GROUPS.CONTENT,
    priority: 4,
  },
  {
    label: 'Quora Ads',
    value: MarketingServiceType.QUORA_ADS,
    svgIcon: svgs.quora,
    group: MARKETING_CHANNELS_GROUPS.CONTENT,
    priority: 3,
  },
  {
    label: 'Reddit Ads',
    value: MarketingServiceType.REDDIT_ADS,
    svgIcon: svgs.reddit,
    group: MARKETING_CHANNELS_GROUPS.CONTENT,
    priority: 0,
  },
  {
    label: 'Taboola',
    value: MarketingServiceType.TABOOLA,
    svgIcon: svgs.taboola,
    group: MARKETING_CHANNELS_GROUPS.CONTENT,
    priority: 1,
  },
  {
    label: 'Twitter Ads',
    value: MarketingServiceType.TWITTER_ADS,
    svgIcon: svgs.twitter,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 2,
  },
  { label: 'VK Ads', value: MarketingServiceType.VK_ADS, svgIcon: svgs.vk },
  {
    label: 'YouTube Advertising',
    value: MarketingServiceType.YOUTUBE_ADS,
    svgIcon: svgs.youtube,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 5,
  },
  { label: 'Yahoo Gemini', value: MarketingServiceType.YAHOO_GEMINI, svgIcon: svgs.yahoo },
  { label: 'Apple Search Ads', value: MarketingServiceType.APPLE_SEARCH_ADS, svgIcon: svgs.apple },
  {
    label: 'TikTok Ads',
    value: MarketingServiceType.TIKTOK_ADS,
    svgIcon: svgs.tiktok,
    group: MARKETING_CHANNELS_GROUPS.SOCIAL_NETWORKS,
    priority: 4,
  },
  { label: 'Snapchat Ads', value: MarketingServiceType.SNAPCHAT_ADS, svgIcon: svgs.snapchat },
  { label: 'Etsy Ads', value: MarketingServiceType.SKILL_ETSY_ADS },
  { label: 'Twitch Ads', value: MarketingServiceType.SKILL_TWITCH_ADS },
  { label: 'Revcontent', value: MarketingServiceType.SKILL_REVCONTENT },
  { label: 'Spotify Ads', value: MarketingServiceType.SKILL_SPOTIFY_ADS },
  { label: 'Bidvertiser', value: MarketingServiceType.SKILL_BIDVERTISER },
];

export const MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS = pairsToLabelLookup(MARKETING_CHANNELS_PAIRS_WITH_ICONS);

export const MARKETING_SKILLS_PAIRS_WITH_ICONS = [
  ...MARKETING_CHANNELS_PAIRS_WITH_ICONS.map((item) => ({ ...item, group: 'channel' })),
  ...MARKETING_SERVICES_PAIRS_WITH_ICONS.map((item) => ({ ...item, group: 'service' })),
].sort(sortByLabel);

export const MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS = pairsToLabelLookup(MARKETING_SKILLS_PAIRS_WITH_ICONS);

export const PERSONA = {
  COMPANY: 'COMPANY',
  MARKETER: 'MARKETER',
};

export const SIGNUP_AS_TYPE_PAIRS = [
  {
    label: 'I am a company and want to boost my marketing results',
    value: PERSONA.COMPANY,
    svgIcon: svgs.company_blue,
  },
  {
    label: 'I am a marketer and want to offer my superior services',
    value: PERSONA.MARKETER,
    svgIcon: svgs.marketer_blue,
  },
];

export const FACEBOOK_USER_AS = [
  { label: 'Facebook User of the Company', value: 'COMPANY' },
  { label: 'Facebook User of the Marketer', value: 'MARKETER' },
  { label: 'Facebook User of Mayple', value: 'SELECTOM' },
];

export const GOOGLE_USER_AS = [
  { label: 'Google User of the Company', value: 'COMPANY' },
  { label: 'Google User of the Marketer', value: 'MARKETER' },
  { label: 'Google User of Mayple', value: 'SELECTOM' },
];

// export const MARKETER_AVAILABILITY_PAIRS = [
//   { label: 'Full-Time', value: 'FULL_TIME' },
//   { label: 'Part-Time', value: 'PART_TIME' },
//   { label: '1-2 Customers', value: 'SIDE_GIG' },
// ];
// export const MARKETER_AVAILABILITY_PAIRS_LABELS_LOOKUP = pairsToLabelLookup(MARKETER_AVAILABILITY_PAIRS);

export const MARKETER_CURRENT_AVAILABILITY_PAIRS = [
  { label: 'Full-Time', value: 'FULL_AVAILABILITY' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
];

export const MARKETER_CURRENT_AVAILABILITY_PAIRS_LABELS_LOOKUP = pairsToLabelLookup(
  MARKETER_CURRENT_AVAILABILITY_PAIRS,
);

/*
export const BUSINESS_MAIN_GOALS_WITH_ICONS = [
  {
    label: 'Launch a new product or service',
    value: 'LAUNCH_NEW_PRODUCTS_OR_SERVICES',
    svgIcon: svgs.launchNewProductOrServicesLight,
    textOnHover: 'You want to promote a new product for the first time',
  },
  {
    label: 'Improve existing campaigns',
    value: 'IMPROVE_EXISTING_CAMPAIGNS',
    svgIcon: svgs.improveExistingCampaignsLight,
    textOnHover: 'You already have paid campaigns running but you know they could perform better',
  },
  {
    label: 'Increase awareness',
    value: 'INCREASE_AWARENESS',
    svgIcon: svgs.buildingBlue,
    textOnHover: 'You want to impact your audience awareness of your brand or agenda',
    isDeprecated: true,
    deprecated: true,
  },
  {
    label: 'Grow my market share',
    value: 'GROW_MARKET_SHARE',
    svgIcon: svgs.growMarketShareLight,
    textOnHover: 'You know you could have a bigger slice of the cake',
  },
  {
    label: 'Expand worldwide',
    value: 'ENTER_NEW_MARKETS',
    svgIcon: svgs.enterNewMarketsLight,
    textOnHover: 'You are about to enter new markets and need a marketer with relevant experience',
  },
  {
    label: 'Strengthen customer relations',
    value: 'STRENGTHEN_EXISTING_CUSTOMER_RELATIONSHIPS',
    svgIcon: svgs.loveBlue,
    textOnHover: 'You want your customers to be more loyal and keep relations on the move ',
    isDeprecated: true,
    deprecated: true,
  },
];

export const BUSINESS_MAIN_GOALS_WITH_ICONS_LABELS_LOOKUP = pairsToLabelLookup(BUSINESS_MAIN_GOALS_WITH_ICONS);
*/

export const COMPANY_PROJECT_LIFE_CYCLE_PAIRS = [
  { label: 'Discovery', value: 'DISCOVERY', color: colors.cherry },
  { label: 'On-Boarding', value: 'ONBOARDING', color: colors.yellow },
  { label: 'Live', value: 'LIVE', color: colors.teal },
  { label: 'Finished', value: 'FINISHED', color: colors.teal_dark },
  { label: 'Abandoned', value: 'ABANDONED', color: colors.red },
];

export const PROJECT_LIFE_CYCLE_STATUS = {
  DISCOVERY: 'DISCOVERY',
  ONBOARDING: 'ONBOARDING',
  LIVE: 'LIVE',
  FINISHED: 'FINISHED',
  ABANDONED: 'ABANDONED',
};

export const PROPOSAL_STATUS = {
  UNDER_REVIEW_BY_MARKETER: 'UNDER_REVIEW_BY_MARKETER',
  UNDER_REVIEW_BY_COMPANY: 'UNDER_REVIEW_BY_COMPANY',
  CANCELED: 'CANCELED',
  ACCEPTED: 'ACCEPTED',
  PROJECT_CHANGED: 'PROJECT_CHANGED',
};

export const FACEBOOK_TIMEZONE_PAIRS = [
  { value: 0, label: 'Unknown' },
  { value: 1, label: 'America/Los_Angeles' },
  { value: 2, label: 'America/Denver' },
  { value: 3, label: 'Pacific/Honolulu' },
  { value: 4, label: 'America/Anchorage' },
  { value: 5, label: 'America/Phoenix' },
  { value: 6, label: 'America/Chicago' },
  { value: 7, label: 'America/New_York' },
  { value: 8, label: 'Asia/Dubai' },
  { value: 9, label: 'America/Argentina_San_Luis' },
  { value: 10, label: 'America/Argentina_Buenos_Aires' },
  { value: 11, label: 'America/Argentina_Salta' },
  { value: 12, label: 'Europe/Vienna' },
  { value: 13, label: 'Australia/Perth' },
  { value: 14, label: 'Australia/Broken_Hill' },
  { value: 15, label: 'Australia/Sydney' },
  { value: 16, label: 'Europe/Sarajevo' },
  { value: 17, label: 'Asia/Dhaka' },
  { value: 18, label: 'Europe/Brussels' },
  { value: 19, label: 'Europe/Sofia' },
  { value: 20, label: 'Asia/Bahrain' },
  { value: 21, label: 'America/La_Paz' },
  { value: 22, label: 'America/Noronha' },
  { value: 23, label: 'America/Campo_Grande' },
  { value: 24, label: 'America/Belem' },
  { value: 25, label: 'America/Sao_Paulo' },
  { value: 26, label: 'America/Nassau' },
  { value: 27, label: 'America/Dawson' },
  { value: 28, label: 'America/Vancouver' },
  { value: 29, label: 'America/Dawson_Creek' },
  { value: 30, label: 'America/Edmonton' },
  { value: 31, label: 'America/Rainy_River' },
  { value: 32, label: 'America/Regina' },
  { value: 33, label: 'America/Atikokan' },
  { value: 34, label: 'America/Iqaluit' },
  { value: 35, label: 'America/Toronto' },
  { value: 36, label: 'America/Blanc_Sablon' },
  { value: 37, label: 'America/Halifax' },
  { value: 38, label: 'America/St_Johns' },
  { value: 39, label: 'Europe/Zurich' },
  { value: 40, label: 'Pacific/Easter' },
  { value: 41, label: 'America/Santiago' },
  { value: 42, label: 'Asia/Shanghai' },
  { value: 43, label: 'America/Bogota' },
  { value: 44, label: 'America/Costa_Rica' },
  { value: 45, label: 'Asia/Nicosia' },
  { value: 46, label: 'Europe/Prague' },
  { value: 47, label: 'Europe/Berlin' },
  { value: 48, label: 'Europe/Copenhagen' },
  { value: 49, label: 'America/Santo_Domingo' },
  { value: 50, label: 'Pacific/Galapagos' },
  { value: 51, label: 'America/Guayaquil' },
  { value: 52, label: 'Europe/Tallinn' },
  { value: 53, label: 'Africa/Cairo' },
  { value: 54, label: 'Atlantic/Canary' },
  { value: 55, label: 'Europe/Madrid' },
  { value: 56, label: 'Europe/Helsinki' },
  { value: 57, label: 'Europe/Paris' },
  { value: 58, label: 'Europe/London' },
  { value: 59, label: 'Africa/Accra' },
  { value: 60, label: 'Europe/Athens' },
  { value: 61, label: 'America/Guatemala' },
  { value: 62, label: 'Asia/Hong_Kong' },
  { value: 63, label: 'America/Tegucigalpa' },
  { value: 64, label: 'Europe/Zagreb' },
  { value: 65, label: 'Europe/Budapest' },
  { value: 66, label: 'Asia/Jakarta' },
  { value: 67, label: 'Asia/Makassar' },
  { value: 68, label: 'Asia/Jayapura' },
  { value: 69, label: 'Europe/Dublin' },
  { value: 70, label: 'Asia/Jerusalem' },
  { value: 71, label: 'Asia/Kolkata' },
  { value: 72, label: 'Asia/Baghdad' },
  { value: 73, label: 'Atlantic/Reykjavik' },
  { value: 74, label: 'Europe/Rome' },
  { value: 75, label: 'America/Jamaica' },
  { value: 76, label: 'Asia/Amman' },
  { value: 77, label: 'Asia/Tokyo' },
  { value: 78, label: 'Africa/Nairobi' },
  { value: 79, label: 'Asia/Seoul' },
  { value: 80, label: 'Asia/Kuwait' },
  { value: 81, label: 'Asia/Beirut' },
  { value: 82, label: 'Asia/Colombo' },
  { value: 83, label: 'Europe/Vilnius' },
  { value: 84, label: 'Europe/Luxembourg' },
  { value: 85, label: 'Europe/Riga' },
  { value: 86, label: 'Africa/Casablanca' },
  { value: 87, label: 'Europe/Skopje' },
  { value: 88, label: 'Europe/Malta' },
  { value: 89, label: 'Indian/Mauritius' },
  { value: 90, label: 'Indian/Maldives' },
  { value: 91, label: 'America/Tijuana' },
  { value: 92, label: 'America/Hermosillo' },
  { value: 93, label: 'America/Mazatlan' },
  { value: 94, label: 'America/Mexico_City' },
  { value: 95, label: 'Asia/Kuala_Lumpur' },
  { value: 96, label: 'Africa/Lagos' },
  { value: 97, label: 'America/Managua' },
  { value: 98, label: 'Europe/Amsterdam' },
  { value: 99, label: 'Europe/Oslo' },
  { value: 100, label: 'Pacific/Auckland' },
  { value: 101, label: 'Asia/Muscat' },
  { value: 102, label: 'America/Panama' },
  { value: 103, label: 'America/Lima' },
  { value: 104, label: 'Asia/Manila' },
  { value: 105, label: 'Asia/Karachi' },
  { value: 106, label: 'Europe/Warsaw' },
  { value: 107, label: 'America/Puerto_Rico' },
  { value: 108, label: 'Asia/Gaza' },
  { value: 109, label: 'Atlantic/Azores' },
  { value: 110, label: 'Europe/Lisbon' },
  { value: 111, label: 'America/Asuncion' },
  { value: 112, label: 'Asia/Qatar' },
  { value: 113, label: 'Europe/Bucharest' },
  { value: 114, label: 'Europe/Belgrade' },
  { value: 115, label: 'Europe/Kaliningrad' },
  { value: 116, label: 'Europe/Moscow' },
  { value: 117, label: 'Europe/Samara' },
  { value: 118, label: 'Asia/Yekaterinburg' },
  { value: 119, label: 'Asia/Omsk' },
  { value: 120, label: 'Asia/Krasnoyarsk' },
  { value: 121, label: 'Asia/Irkutsk' },
  { value: 122, label: 'Asia/Yakutsk' },
  { value: 123, label: 'Asia/Vladivostok' },
  { value: 124, label: 'Asia/Magadan' },
  { value: 125, label: 'Asia/Kamchatka' },
  { value: 126, label: 'Asia/Riyadh' },
  { value: 127, label: 'Europe/Stockholm' },
  { value: 128, label: 'Asia/Singapore' },
  { value: 129, label: 'Europe/Ljubljana' },
  { value: 130, label: 'Europe/Bratislava' },
  { value: 131, label: 'America/El_Salvador' },
  { value: 132, label: 'Asia/Bangkok' },
  { value: 133, label: 'Africa/Tunis' },
  { value: 134, label: 'Europe/Istanbul' },
  { value: 135, label: 'America/Port_Of_Spain' },
  { value: 136, label: 'Asia/Taipei' },
  { value: 137, label: 'Europe/Kiev' },
  { value: 138, label: 'America/Montevideo' },
  { value: 139, label: 'America/Caracas' },
  { value: 140, label: 'Asia/Ho_Chi_Minh' },
  { value: 141, label: 'Africa/Johannesburg' },
  { value: 142, label: 'Num/Timezones' },
];
export const FACEBOOK_TIMEZONE_PAIRS_VALUES = pairsToValueLookup(FACEBOOK_TIMEZONE_PAIRS);

export const FACEBOOK_TIMEZONE_LIST = pairsToLabelsList(FACEBOOK_TIMEZONE_PAIRS);

export const SELECT_AD_ACCOUNT_OPTIONS_PAIRS = [
  {
    label: 'Create new (recommended)',
    value: 'CREATE_NEW',
    fontIcon: iconCodes.add_person,
    fontIconColor: colors.blue,
  },
  {
    label: 'Select existing',
    value: 'KEEP_EXISTING',
    fontIcon: iconCodes.supervisor,
    fontIconColor: colors.blue,
  },
];

export const PAYMENT_METHODS_PAIRS = [
  {
    label: 'Credit Card',
    value: PAYMENT_METHODS.CREDIT_CARD,
    svgIcon: svgs.credit_card,
  },
  {
    label: 'PayPal',
    value: 'PAYPAL',
    svgIcon: svgs.paypal,
  },
];

export const PAYMENT_METHODS_LABELS = pairsToLabelLookup(PAYMENT_METHODS_PAIRS);

export const PAYOUT_METHOD_PAIRS = [
  { label: 'PayPal', value: PayoutType.PAYPAL, svgIcon: svgs.paypal },
  { label: 'Wire Transfer', value: PayoutType.WIRE_TRANSFER, svgIcon: svgs.wireTransfer },
];
export const PAYOUT_METHOD_LABELS = pairsToLabelLookup(PAYOUT_METHOD_PAIRS);

export const REMOTE_WORK_PAIRS = [
  {
    label: 'Someone in my country',
    value: REMOTE_WORK.DOMESTIC,
    svgIcon: svgs.remoteWorkDomesticLight,
  },
  {
    label: 'Someone in my target markets',
    value: REMOTE_WORK.ON_TARGET,
    svgIcon: svgs.remoteWorkOnTargetLight,
  },
  {
    label: 'I don’t have any preferences',
    value: REMOTE_WORK.NO_PREFERENCE,
    svgIcon: svgs.remoteWorkNoPreferenceLight,
  },
];
export const REMOTE_WORK_LOOKUP = pairsToLabelLookup(REMOTE_WORK_PAIRS);

export const LAUNCH_TIME_PAIRS = [
  {
    label: 'Right away',
    value: 'UP_TO_ONE_WEEK',
    svgIcon: svgs.UP_TO_ONE_WEEK_LIGHT,
  },
  {
    label: 'Up to 1 month',
    value: 'UP_TO_ONE_MONTH',
    svgIcon: svgs.UP_TO_ONE_MONTH_LIGHT,
  },
  {
    label: 'In 1+ month',
    value: 'ONE_MONTH_OR_MORE',
    svgIcon: svgs.ONE_MONTH_OR_MORE_LIGHT,
  },
  {
    label: "I'm still checking my options",
    value: 'UNSURE',
    svgIcon: svgs.UNSURE_LIGHT,
  },
];

export const LAUNCH_TIME_LABELS_LOOKUP = pairsToLabelLookup(LAUNCH_TIME_PAIRS);

export const YES_OR_NO_PAIRS = [
  { label: 'YES', value: 'true' },
  { label: 'NO', value: 'false' },
];

export const YES_OR_NO_LABELS_LOOKUP = pairsToLabelLookup(YES_OR_NO_PAIRS);

export const MARKETER_FEE_PLAN_PAIRS = [
  { value: 'percentage', label: 'Percentage' },
  { value: 'fixedToMarketer', label: 'Fixed fee for marketer' },
  { value: 'fixedToMayple', label: 'Fixed fee for Mayple' },
];

export const AUTO_SUGGEST_SKILLS_WITH_ICONS = [
  {
    label: "I want to get Mayple's recommendation",
    value: AUTO_SUGGEST_SKILLS.AUTO,
    svgIcon: svgs.AUTO_SUGGEST_SKILLS_AUTO_LIGHT,
  },
  {
    label: 'I know what services I need for my project',
    value: AUTO_SUGGEST_SKILLS.MANUAL,
    svgIcon: svgs.AUTO_SUGGEST_SKILLS_MANUAL_LIGHT,
  },
];

export const EXPERIENCE_LEVEL_WITH_ICONS = [
  {
    label: "Yes, I'm familiar with digital marketing",
    value: 'EXPERIENCED',
    svgIcon: svgs.experiencedLight,
  },
  {
    label: "No, I'm new to digital marketing",
    value: 'NOVICE',
    svgIcon: svgs.noviceLight,
  },
];

export const IS_ECOMMERCE_PAIRS = [
  {
    label: 'Yes, I do!',
    value: 'YES',
  },
  {
    label: 'No, I run a B2B / lead-gen / app / services activity',
    value: 'NO',
  },
];

export const MATCH_VOTE = [
  {
    label: 'Excellent',
    value: 'EXCELLENT',
  },
  {
    label: 'good',
    value: 'GOOD',
  },
  {
    label: 'Okay',
    value: 'OKAY',
  },
  {
    label: 'Bad',
    value: 'BAD',
  },
  {
    label: 'Terrible',
    value: 'TERRIBLE',
  },
];

export const RECURRING_SERVICES_PAIRS = [
  {
    label: 'Only this monthly cycle',
    value: 'FALSE',
  },
  {
    label: 'Continue to the next monthly cycle',
    value: 'TRUE',
  },
];

export const PRODUCT_BUSINESS_MODEL_PAIRS = [
  {
    label: 'B2B',
    value: 'B2B',
  },
  {
    label: 'B2C',
    value: 'B2C',
  },
];

export const PRODUCT_BUSINESS_MODEL_LABELS_LOOKUP = pairsToLabelLookup(PRODUCT_BUSINESS_MODEL_PAIRS);

export const SUPPORTED_CURRENCIES = [
  {
    label: 'USD',
    value: 'USD',
    name: 'US Dollar',
    symbol: '$',
  },
  {
    label: 'GBP',
    value: 'GBP',
    name: 'Pound sterling',
    symbol: '£',
  },
  {
    label: 'EUR',
    value: 'EUR',
    name: 'Euro',
    symbol: '€',
  },
  {
    label: 'ILS',
    value: 'ILS',
    name: 'Israeli Shekel',
    symbol: '₪',
  },
];

export const PARTICIPANT_ONBOARDING_STATUS = {
  STARTED_ONBOARDING: 'STARTED_ONBOARDING',
  SCHEDULED_KICK_OFF_MEETING: 'SCHEDULED_KICK_OFF_MEETING',
  FINISHED_KICK_OFF_MEETING: 'FINISHED_KICK_OFF_MEETING',
  SCHEDULED_MARKETING_PLAN_MEETING: 'SCHEDULED_MARKETING_PLAN_MEETING',
  APPROVED_MARKETING_PLAN: 'APPROVED_MARKETING_PLAN',
};

export const PARTICIPANT_ONBOARDING_STATUS_PAIRS = [
  {
    label: 'Started Onboarding',
    value: PARTICIPANT_ONBOARDING_STATUS.STARTED_ONBOARDING,
  },
  {
    label: 'Scheduled kick off meeting',
    value: PARTICIPANT_ONBOARDING_STATUS.SCHEDULED_KICK_OFF_MEETING,
  },
  {
    label: 'Finished kick off meeting',
    value: PARTICIPANT_ONBOARDING_STATUS.FINISHED_KICK_OFF_MEETING,
  },
  {
    label: 'Scheduled marketing plan meeting',
    value: PARTICIPANT_ONBOARDING_STATUS.SCHEDULED_MARKETING_PLAN_MEETING,
  },
  {
    label: 'Approved marketing plan',
    value: PARTICIPANT_ONBOARDING_STATUS.APPROVED_MARKETING_PLAN,
  },
];

export const PROJECT_FUNDS_REMAINDER_BEHAVIOR = [
  {
    value: 'TRANSFER_TO_COMPANY_AS_CREDIT',
    label: 'Transfer remaining funds to company as credit',
    tooltip:
      'When the project stops and all monthly cycles have been billed successfully, all remaining funds will be transferred to the company as credit which can be used in new or existing projects.',
  },
  {
    value: 'PAY_TO_MAYPLE_AS_FEES',
    label: 'Pay remaining funds to Mayple as fees',
    tooltip:
      'When the project stops and all monthly cycles have been billed successfully, all remaining funds will be paid to Mayple as fees.',
  },
];
export const PROJECT_FUNDS_REMAINDER_BEHAVIOR_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_FUNDS_REMAINDER_BEHAVIOR);

export const DEPOSIT_SOURCE = [
  {
    value: 'PAYPAL',
    label: 'Payment made via Paypal',
  },
  {
    value: 'CREDIT_CARD',
    label: 'Payment made via credit card',
  },
  {
    value: 'CASH',
    label: 'Payment made by cash',
  },
  {
    value: 'WIRE_TRANSFER',
    label: 'Payment made by wire transfer / bank transfer',
  },
];
export const DEPOSIT_SOURCE_LABELS_LOOKUP = pairsToLabelLookup(DEPOSIT_SOURCE);

export const PROJECT_FINISH_REASON = [
  { value: 'NOT_HAPPY_WITH_MARKETER', label: 'Not happy with marketer' },
  { value: 'NOT_HAPPY_WITH_MAYPLE', label: 'Not happy with Mayple' },
  { value: 'NOT_HAPPY_WITH_MARKETING_RESULTS', label: 'Not happy with marketing results' },
  { value: 'TOO_EXPENSIVE', label: 'Too expensive' },
  {
    value: 'COMPANY_FOUND_ANOTHER_EXTERNAL_SOLUTION',
    label: 'Company found another external solution (freelancer, agency)',
  },
  { value: 'COMPANY_FOUND_ANOTHER_INTERNAL_SOLUTION', label: 'Company found another internal solution' },
  {
    value: 'COMPANY_DECIDED_TO_WIND_DOWN_MARKETING_ACTIVITIES',
    label: 'Company decided to wind down marketing activities (business problems)',
  },
  {
    value: 'MAYPLE_DECIDED_TO_STOP_WORKING_WITH_THE_CUSTOMER',
    label: 'Mayple decided to stop working with the customer',
  },
  { value: 'PROJECT_WAS_SEASONAL', label: 'Project was seasonal' },
  { value: 'CONTINUED_TO_ANOTHER_PROJECT', label: 'Continued to another project' },
  { value: 'OTHER_REASON', label: 'Other reason' },
];

export const PROJECT_FINISH_REASON_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_FINISH_REASON);

export const PRODUCT_COST_RANGE = [
  {
    value: 'TILL_20',
    label: 'Up to $20',
  },
  {
    value: 'FROM_20_TO_50',
    label: '$20 - $50',
  },
  {
    value: 'FROM_50_TO_100',
    label: '$50 - $100',
  },
  {
    value: 'FROM_100_TO_500',
    label: '$100 - $500',
  },
  {
    value: 'OVER_500',
    label: '$500+',
  },
];
export const PRODUCT_COST_RANGE_LABELS_LOOKUP = pairsToLabelLookup(PRODUCT_COST_RANGE);

export const CLIENT_STAGE_EXPERTISE = [
  {
    value: 'STARTUPS',
    label: 'Start-ups',
  },
  {
    value: 'NEW_BUSINESSES',
    label: 'New businesses',
  },
  {
    value: 'ESTABLISHED_BUSINESSES',
    label: 'Established Business',
  },
  {
    value: 'FORTUNE_500_COMPANIES',
    label: 'Fortune 500 companies',
  },
];
export const CLIENT_STAGE_EXPERTISE_LABELS_LOOKUP = pairsToLabelLookup(CLIENT_STAGE_EXPERTISE);

export const CURRENT_WORK_STATUS = [
  {
    value: 'FULLTIME_FREELANCER',
    label: 'Full-time freelancer',
  },
  {
    value: 'FULLTIME_JOB',
    label: 'Full-time job',
  },
  {
    value: 'NEW_FREELANCER',
    label: 'New-freelancing',
  },
  {
    value: 'PARTTIME_FREELANCER',
    label: 'Part-time-freelance',
  },
  {
    value: 'SEARCHING_FOR_A_FULLTIME_JOB',
    label: 'Searching for a full-time job',
  },
];
export const CURRENT_WORK_STATUS_LABELS_LOOKUP = pairsToLabelLookup(CURRENT_WORK_STATUS);

export const PROJECT_SPECIAL_TYPE = [
  {
    value: ProjectSpecialType.NO_SPECIAL_TYPE,
    label: 'No special type',
  },
  {
    value: ProjectSpecialType.MAILCHIMP_AUDIT_TYPE,
    label: 'MailChimp',
  },
  {
    value: ProjectSpecialType.MARKETER_INVITE_TYPE,
    label: 'Marketer Invite',
  },
  {
    value: ProjectSpecialType.UPSELL,
    label: 'Upsell',
  },
  {
    value: ProjectSpecialType.MAILCHIMP_LIVE_TYPE,
    label: 'Live',
  },
  {
    value: ProjectSpecialType.OUTBRAIN_AUDIT_TYPE,
    label: 'Outbrain',
  },
  {
    value: ProjectSpecialType.MIXPANEL_AUDIT_TYPE,
    label: 'Mixpanel',
  },
];
export const PROJECT_SPECIAL_TYPE_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_SPECIAL_TYPE);

export const PROJECT_SPECIAL_SUBTYPE_PAIRS = [
  {
    value: ProjectSpecialSubType.NO_SPECIAL_SUB_TYPE,
    label: 'No special subtype',
  },
  {
    value: ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK,
    label: 'MailChimp health check',
  },
  {
    value: ProjectSpecialSubType.MAILCHIMP_HEALTH_CHECK_V2,
    label: 'MailChimp health check V2',
  },
  {
    value: ProjectSpecialSubType.MAILCHIMP_IMPLEMENTATION,
    label: 'MailChimp Implementation',
  },
  {
    value: ProjectSpecialSubType.MAILCHIMP_UPSELL,
    label: 'MailChimp Upsell',
  },
  {
    value: ProjectSpecialSubType.OUTBRAIN_HEALTH_CHECK,
    label: 'Outbrain health check',
  },
  {
    value: ProjectSpecialSubType.MIXPANEL_HEALTH_CHECK,
    label: 'Mixpanel health check',
  },
];
export const PROJECT_SPECIAL_SUBTYPE_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_SPECIAL_SUBTYPE_PAIRS);

export const MARKETER_GRADUATION_PLAN = [
  {
    value: MarketerGraduationPlan.TIER_1,
    label: 'Tier 1',
  },
  {
    value: MarketerGraduationPlan.TIER_2,
    label: 'Tier 2',
  },
  {
    value: MarketerGraduationPlan.TIER_3,
    label: 'Tier 3',
  },
];
export const MARKETER_GRADUATION_PLAN_LABELS_LOOKUP = pairsToLabelLookup(MARKETER_GRADUATION_PLAN);

export const DELIVERABLES_FULFILLMENT_BEHAVIOR = [
  {
    value: DeliverablesFulfillmentBehavior.MOVE_REMAINING_TO_NEXT_CYCLE,
    label: 'Move Remaining to Next Cycle',
  },
  {
    value: DeliverablesFulfillmentBehavior.RENEW_IN_NEXT_CYCLE,
    label: 'Renew in Next Cycle',
  },
  {
    value: DeliverablesFulfillmentBehavior.STOP_IN_NEXT_CYCLE,
    label: 'Stop in Next Cycle',
  },
];
export const DELIVERABLES_FULFILLMENT_BEHAVIOR_LABELS_LOOKUP = pairsToLabelLookup(DELIVERABLES_FULFILLMENT_BEHAVIOR);

// Based on MarketingServiceType, NonPPCMarketingServiceType and PPCMarketingServiceType
export const PPC_MARKETING_SERVICE_TYPES_WITH_ICONS = MARKETING_CHANNELS_PAIRS_WITH_ICONS;
export const PPC_MARKETING_SERVICE_TYPES_WITH_ICONS_LABELS_LOOKUP = MARKETING_CHANNELS_LABELS_LOOKUP_WITH_ICONS;
export const NON_PPC_MARKETING_SERVICE_TYPES_WITH_ICONS = MARKETING_SERVICES_PAIRS_WITH_ICONS;
export const NON_PPC_MARKETING_SERVICE_TYPES_WITH_ICONS_LABELS_LOOKUP =
  MARKETING_SERVICES_PAIRS_WITH_ICONS_LABELS_LOOKUP;

export const MARKETING_SERVICE_TYPES_WITH_ICONS = PPC_MARKETING_SERVICE_TYPES_WITH_ICONS.concat(
  NON_PPC_MARKETING_SERVICE_TYPES_WITH_ICONS,
);
export const MARKETING_SERVICE_TYPES_WITH_ICONS_LABELS_LOOKUP = pairsToLabelLookup(MARKETING_SERVICE_TYPES_WITH_ICONS);

export const PROJECT_CYCLE_OPERATION_MODE_PAIRS = [
  {
    value: ProjectCycleOperationMode.OPERATED_AUTOMATICALLY_BY_PROJECT_PACKAGES,
    label: 'Enabled, Automatic Billing',
  },
  {
    value: ProjectCycleOperationMode.OPERATED_MANUALLY_BY_HUMANS,
    label: 'Disabled, Manual Billing',
  },
];

export const PROJECT_CYCLE_OPERATION_MODE_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_CYCLE_OPERATION_MODE_PAIRS);

export const PROJECT_PACKAGE_STATE_PAIRS = [
  {
    value: ProjectPackageState.PACKAGE_DRAFT,
    label: 'Draft',
  },
  {
    value: ProjectPackageState.PACKAGE_PENDING,
    label: 'Pending',
  },
  {
    value: ProjectPackageState.PACKAGE_STARTED,
    label: 'Started',
  },
  {
    value: ProjectPackageState.PACKAGE_CANCELED,
    label: 'Canceled',
  },
  {
    value: ProjectPackageState.PACKAGE_COMPLETED,
    label: 'Completed',
  },
];
export const PROJECT_PACKAGE_STATE_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_PACKAGE_STATE_PAIRS);

export const PROJECT_PACKAGE_DELAY_REASON_PAIRS = [
  {
    value: ProjectPackageDelayReason.DELAY_REASON_NOT_SPECIFIED,
    label: 'Not specific',
  },
  {
    value: ProjectPackageDelayReason.DELAY_REASON_CLIENT_NON_RESPONSIVE,
    label: 'Client - Non responsive',
  },
  {
    value: ProjectPackageDelayReason.DELAY_REASON_CLIENT_TECHNICAL_ISSUES,
    label: 'Client - Technical issues',
  },
  {
    value: ProjectPackageDelayReason.DELAY_REASON_EXPERT_CAUSED_DELAY,
    label: 'Expert caused delay',
  },
];

export const PROJECT_PACKAGE_DELAY_REASON_LABELS_LOOKUP = pairsToLabelLookup(PROJECT_PACKAGE_DELAY_REASON_PAIRS);

export const ACCOUNT_MANAGER_PAIRS = [
  {
    value: AccountManager.FREELANCER,
    label: 'Freelancer',
  },
  {
    value: AccountManager.AGENCY,
    label: 'Agency',
  },
  {
    value: AccountManager.IN_HOUSE,
    label: 'In House',
  },
];

export const ACCOUNT_MANAGER_LABELS_LOOKUP = pairsToLabelLookup(ACCOUNT_MANAGER_PAIRS);
