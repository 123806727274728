import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectExistingMarketingEvaluationState, SharedFileCategory } from '@mayple/types';
import Divider from '@material-ui/core/Divider';

import { MarketerProjectMarketingEvaluationAuditReportProps } from './types';
import useMarketerProjectMarketingEvaluationAuditReportData from './useMarketerProjectMarketingEvaluationAuditReportData';
import useUploadParticipantSharedFile from '../../../../hooks/useUploadParticipantSharedFile';
import { formatDate } from '../../../../app/utils';
import { config } from '../../../../config';

import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { RefreshSpinner } from '../../../../../fe_common/client/components/atoms';
import FilesList from '../../../../../fe_common/client/components/molecules/FilesList';
import { FileListFile } from '../../../../../fe_common/client/components/molecules/FilesList/types';
import Section from '../../../../../fe_common/client/components/atoms/Section';
import SectionContent from '../../../../../fe_common/client/components/atoms/Section/SectionContent';
import FileUploadButton from '../../../../../fe_common/client/components/inputs/FileUploadButton';
import { FileUploadDialogTabs } from '../../../../../fe_common/client/components/inputs/FileUploadDialog/types';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';

import useStyles from './style';
import MarketerFeedbackReportDetails from './MarketerFeedbackReportDetails';

const MarketerProjectMarketingEvaluationAuditReport: React.FC<MarketerProjectMarketingEvaluationAuditReportProps> = (
  props,
) => {
  const classes = useStyles(props);
  const { hasAssignedGS, noBorder } = props;
  const {
    loadingEvaluation,
    loadingApproveEvaluation,
    saving,
    evaluationData,
    onApproveClick,
    onSetCommentsByGSClick,
    onApproveExistingMarketingEvaluationReportByCompanyClickHandler,
  } = useMarketerProjectMarketingEvaluationAuditReportData(props);

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'MarketerProjectMarketingEvaluationAuditReport' });

  const { loading: savingFile, onFileUpload } = useUploadParticipantSharedFile({
    participant: props?.participant,
    sharedFileCategory: SharedFileCategory.EXISTING_MARKETING_EVALUATION_REPORT_FILE,
  });

  const healthCheckFile = evaluationData?.healthCheckFile;

  // eslint-disable-next-line no-underscore-dangle
  const translationKeyPrefix = evaluationData?.reportDetails?.__typename;

  if (loadingEvaluation || loadingApproveEvaluation) {
    return null;
  }

  return (
    <Section
      title={t(`${translationKeyPrefix}.title`)}
      noBorder={noBorder}
      noTopMargin={noBorder}
      classes={{ root: classes.root }}
    >
      <SectionContent>
        {loadingEvaluation && <RefreshSpinner linear />}
        {evaluationData && (
          <div>
            <TextDisplay gutterBottom>Current state: {evaluationData.state}</TextDisplay>
            <Divider />
            {evaluationData.evaluationKickOffMeeting && (
              <>
                <TextDisplay gutterBottom>
                  {t(`${translationKeyPrefix}.kickOffMeetingDate`)}{' '}
                  {formatDate(evaluationData.evaluationKickOffMeeting.start)} UTC
                </TextDisplay>
                <Divider />
              </>
            )}
            {evaluationData.evaluationReviewMeeting && (
              <>
                <TextDisplay gutterBottom>
                  {t(`${translationKeyPrefix}.reviewMeetingDate`, 'Review meeting scheduled at:')}
                  {formatDate(evaluationData.evaluationReviewMeeting.start)} UTC
                </TextDisplay>
                <Divider />
              </>
            )}

            {(evaluationData?.evaluationFiles || []).length > 0 && (
              <div>
                <TextDisplay gutterBottom>
                  {t('filesUploadedByMarketer', 'Evaluation files uploaded by marketer:')}
                </TextDisplay>
                <div className={classes.healthCheckFileContainer}>
                  <div className={classes.fileListContainer}>
                    <FilesList
                      files={evaluationData.evaluationFiles as FileListFile[]}
                      allowDownload
                      showFileDate
                      showFileSize
                      classes={{ root: classes.fileListRoot }}
                    />
                    <div>
                      <FileUploadButton
                        label={t('replaceGoogleSlide', 'Upload / replace Google slide')}
                        variant="outlined"
                        filestackApiKey={config.filestackApiKey}
                        tabs={[FileUploadDialogTabs.GoogleSlides]}
                        disabled={!healthCheckFile}
                        filename="Mailchimp health check"
                        category={SharedFileCategory.EXISTING_MARKETING_EVALUATION_REPORT_FILE}
                        loading={savingFile}
                        onFileUpload={onFileUpload}
                      />
                    </div>
                  </div>

                  <div className={classes.moveToMarketerFixesButton}>
                    <Button
                      label="Sending back to the expert for fixes"
                      variant="outlined"
                      color="primary"
                      loading={saving}
                      disabled={
                        saving ||
                        evaluationData?.state !==
                          ProjectExistingMarketingEvaluationState.EVALUATION_REVIEWED_BY_MARKETER
                      }
                      onClick={onSetCommentsByGSClick}
                    />
                  </div>
                </div>
                <Divider />
              </div>
            )}

            <MarketerFeedbackReportDetails reportDetails={evaluationData.reportDetails} />

            {/*
            {!!Component && (
              <Component
                // @ts-ignore
                reportDetails={evaluationData.reportDetails}
              />
            )}
            */}
            <div>
              {evaluationData.isWaitingForGSApproval && (
                <Button
                  label="Approve Health check"
                  color="primary"
                  variant="contained"
                  disabled={loadingApproveEvaluation || loadingEvaluation || !hasAssignedGS}
                  onClick={onApproveClick}
                />
              )}

              {evaluationData.isApprovedByGS && (
                <CalloutMessage type="info" message="Health check was approved by GS." />
              )}
            </div>

            <Divider />

            <div>
              {evaluationData.isWaitingForCompanyApproval && (
                <Button
                  label="Approve Health check on behalf of the company"
                  color="primary"
                  variant="contained"
                  disabled={loadingApproveEvaluation || loadingEvaluation || !hasAssignedGS}
                  onClick={onApproveExistingMarketingEvaluationReportByCompanyClickHandler}
                />
              )}
              {evaluationData.isApprovedByCompany && (
                <CalloutMessage type="info" message="Health check was approved by the Company." />
              )}
            </div>
          </div>
        )}
      </SectionContent>
    </Section>
  );
};

export default MarketerProjectMarketingEvaluationAuditReport;
