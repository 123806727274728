import { PROPS_GROUPS } from './index';

import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';
import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';
import {
  numberOutOfNumberFormatter,
  numericFormatter,
  percentageFormatter,
  periodInUnitsFormatter,
  yesNoFormatter,
} from '../../../../../../fe_common/client/services/ExportToCSV/formatters';

const getProjectExistingMarketingEvaluationReportV1DetailsQuestions = () => {
  const questions: PropertiesMetaType[] = [
    {
      label: 'duration',
      path: 'duration',
      formatter: periodInUnitsFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'improvementPotential',
      path: 'improvementPotential',
      formatter: percentageFormatter(0),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'numberOfDaysToImplement',
      path: 'numberOfDaysToImplement',
      formatter: periodInUnitsFormatter('days'),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'activeCampaigns',
      path: 'activeCampaigns',
      formatter: numericFormatter,
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'activeFlows',
      path: 'activeFlows',
      formatter: numericFormatter,
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'overallAccountHealthScore',
      path: 'overallAccountHealthScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'accountSetupScore',
      path: 'accountSetupScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'deliverabilityScore',
      path: 'deliverabilityScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'formsScore',
      path: 'formsScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'integrationsScore',
      path: 'integrationsScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'listsScore',
      path: 'listsScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'testingAndExperimentsScore',
      path: 'testingAndExperimentsScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },

    {
      label: 'isTrackingTransactionsIntegratedWithMailchimp',
      path: 'isTrackingTransactionsIntegratedWithMailchimp',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isTrackingRevenueIntegratedWithMailchimp',
      path: 'isTrackingRevenueIntegratedWithMailchimp',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingTagsAndGroups',
      path: 'isUsingTagsAndGroups',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },

    {
      label: 'isUsingMailchimpOfferedSegments',
      path: 'isUsingMailchimpOfferedSegments',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingPredictiveSegments',
      path: 'isUsingPredictiveSegments',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingEventsAPI',
      path: 'isUsingEventsAPI',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isOptDownVsOnlyOptingOut',
      path: 'isOptDownVsOnlyOptingOut',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isAudiencePermissionReminderClear',
      path: 'isAudiencePermissionReminderClear',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingCustomerJourneyBuilder',
      path: 'isUsingCustomerJourneyBuilder',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isEmailDomainAuthenticated',
      path: 'isEmailDomainAuthenticated',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'hasABorMVTTest',
      path: 'hasABorMVTTest',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'hasAccessibleEmails',
      path: 'hasAccessibleEmails',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingTemplates',
      path: 'isUsingTemplates',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingCustomCodedTemplates',
      path: 'isUsingCustomCodedTemplates',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingNewEmailEditor',
      path: 'isUsingNewEmailEditor',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingSMCBlocks',
      path: 'isUsingSMCBlocks',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'hasUsedContentOptimizer',
      path: 'hasUsedContentOptimizer',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingBenchmarkingReports',
      path: 'isUsingBenchmarkingReports',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isUsingComparativeReports',
      path: 'isUsingComparativeReports',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },
    {
      label: 'isDownloadingOrExportingReports',
      path: 'isDownloadingOrExportingReports',
      formatter: yesNoFormatter,
      group: PROPS_GROUPS.YES_NO_QUESTIONS,
    },

    {
      label: 'biggestGap',
      path: 'biggestGap',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'otherBusinessManagementTools',
      path: 'otherBusinessManagementTools',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'multipleAudiencesVsMultipleLists',
      path: 'multipleAudiencesVsMultipleLists',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
  ];

  return questions;
};

export default getProjectExistingMarketingEvaluationReportV1DetailsQuestions;
