import React, { useMemo } from 'react';
import {
  ProjectExistingMarketingEvaluationReportV1Details,
  ProjectExistingMarketingEvaluationReportV2Details,
  ProjectExistingMarketingEvaluationReportV3Details,
  ProjectExistingMarketingEvaluationReportV4Details,
} from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { useTranslation } from 'react-i18next';

import { getMarketerFeedbackReportQuestions } from './logic';

import { PropertiesList, TextDisplay } from '../../../../../fe_common/client/components/display';
import { TRANSLATION_NS } from '../../../../../fe_common/client/app/consts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    propertyWrapper: {
      gap: '1rem',
    },
    propertyLabel: {
      flexBasis: 'auto',
    },
    propertyValue: {},
  }),
);

type MarketerFeedbackReportDetailsProps = {
  reportDetails:
    | null
    | undefined
    | ProjectExistingMarketingEvaluationReportV1Details
    | ProjectExistingMarketingEvaluationReportV2Details
    | ProjectExistingMarketingEvaluationReportV3Details
    | ProjectExistingMarketingEvaluationReportV4Details;
};

const MarketerFeedbackReportDetails: React.FC<MarketerFeedbackReportDetailsProps> = (props) => {
  const classes = useStyles(props);
  const { reportDetails } = props;

  const { t } = useTranslation(TRANSLATION_NS, {
    keyPrefix: 'MarketerProjectMarketingEvaluationAuditReport',
  });

  const questions = useMemo(() => getMarketerFeedbackReportQuestions(t, reportDetails), [reportDetails, t]);

  return (
    <div className={classes.root}>
      <TextDisplay variant="h5" gutterBottom>
        Marketer Feedback Form
      </TextDisplay>
      <PropertiesList
        id="MarketerFeedbackReportDetails"
        data={reportDetails}
        propertiesMeta={questions}
        classes={{
          propertyWrapper: classes.propertyWrapper,
          propertyLabel: classes.propertyLabel,
          propertyValue: classes.propertyValue,
        }}
      />
    </div>
  );
};

export default MarketerFeedbackReportDetails;
