import { ProjectMeetingType, ProjectScheduledMeeting } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProjectScheduledMeetingsQuery } from 'growl-graphql/dist/queries/project/ProjectScheduledMeetingsQuery';

interface ReturnType {
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<any>>;
  error?: ApolloError;
  meetings: ProjectScheduledMeeting[];
}

/**
 * Hook for fetching all scheduled meetings of a project, filtered by the ProjectMeetingType enum.
 * It will also fetch past meetings.
 * @param projectId
 * @param projectMeetingType
 * @param futureMeetingsOnly
 */
const useProjectScheduledMeetings = (
  projectId: number | null | undefined,
  projectMeetingType: ProjectMeetingType | null | undefined,
  futureMeetingsOnly = false,
): ReturnType => {
  const { data, loading, refetch, error } = useQuery(ProjectScheduledMeetingsQuery.query, {
    variables: { projectId, workflowStatus: projectMeetingType },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  let meetings: ProjectScheduledMeeting[] = data?.cronofyProjectScheduledMeetings || [];

  if (futureMeetingsOnly) {
    const currentTime = new Date().getTime();

    meetings = meetings.filter(({ end }) => currentTime <= new Date(end).getTime());
  }

  return {
    meetings,
    loading,
    refetch,
    error,
  };
};

export default useProjectScheduledMeetings;
