import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useFeedState } from '../FeedContext';
import { colors } from '../../../app/theme';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
  },
}));

function FeedPostTopic(props) {
  const classes = useStyles(props);
  const { topic, isSelected, handleToggle, className } = props;
  const { topics } = useFeedState();

  const defaultBgColor = colors.white_darker;
  const defaultFgColor = colors.black_light;
  let { bgColor = defaultBgColor, fgColor = defaultFgColor } = topics?.[topic] || {};

  let extraProps = {};

  if (handleToggle && typeof handleToggle === 'function') {
    if (!isSelected) {
      bgColor = defaultBgColor;
      fgColor = defaultFgColor;
    }
    const icon = isSelected ? <CheckIcon style={{ color: fgColor }} /> : <AddIcon style={{ color: fgColor }} />;
    extraProps = {
      deleteIcon: icon,
      onClick: handleToggle(topic),
      onDelete: handleToggle(topic),
    };
  }
  return (
    <Chip
      key={`TopicsFilter-${topic}`}
      label={topic.replace('_', ' ')} // Some topics are with _
      className={className}
      classes={{ root: classes.root }}
      style={{
        color: fgColor,
        backgroundColor: bgColor,
      }}
      {...extraProps}
    />
  );
}

FeedPostTopic.propTypes = {
  topic: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleToggle: PropTypes.func,
  className: PropTypes.string,
};

FeedPostTopic.defaultProps = {
  withIcon: false,
  isSelected: false,
  handleToggle: null,
  className: '',
};

export default FeedPostTopic;
