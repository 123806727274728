import { Project, ProjectSpecialType, ProjectStage } from '@mayple/types';

const irrelevantProjectTypes: ProjectSpecialType[] = [
  ProjectSpecialType.MAILCHIMP_AUDIT_TYPE,
  ProjectSpecialType.OUTBRAIN_AUDIT_TYPE,
  ProjectSpecialType.MIXPANEL_AUDIT_TYPE,
  ProjectSpecialType.MAILCHIMP_LIVE_TYPE,
];

const getShouldShowDelayedProjectLaunch = (project: Project): boolean => {
  if (irrelevantProjectTypes.includes(project.specialType)) {
    return false;
  }

  return !(
    !project.projectLaunchParameters?.launchDate &&
    project.stage !== ProjectStage.LAUNCH &&
    project.stage !== ProjectStage.MARKETER_APPROVAL
  );
};

export default getShouldShowDelayedProjectLaunch;
