import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';

import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';

import {
  numberOutOfNumberFormatter,
  percentageFormatter,
  yesNoFormatter,
} from '../../../../../../fe_common/client/services/ExportToCSV/formatters';

const getProjectExistingMarketingEvaluationReportV4DetailsQuestions = () => {
  const questions: PropertiesMetaType[] = [
    {
      label: 'overallAccountHealthScore',
      path: 'overallAccountHealthScore',
      formatter: numberOutOfNumberFormatter(10),
    },
    {
      label: 'improvementPotential',
      path: 'improvementPotential',
      formatter: percentageFormatter(0),
    },
    {
      label: 'additionalHelpNeeded',
      path: 'additionalHelpNeeded',
      formatter: yesNoFormatter,
    },
    {
      label: 'additionalHelpDetails',
      path: 'additionalHelpDetails',
      customComponent: RichTextViewer,
    },
  ];

  return questions;
};

export default getProjectExistingMarketingEvaluationReportV4DetailsQuestions;
