import { PROPS_GROUPS } from './index';

import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';
import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';
import {
  currencyFormatter,
  labelValueFormatter,
  percentageFormatter,
} from '../../../../../../fe_common/client/services/ExportToCSV/formatters';
import { ACCOUNT_MANAGER_LABELS_LOOKUP } from '../../../../../../fe_common/client/app/enums';

const getProjectExistingMarketingEvaluationReportV3DetailsQuestions = () => {
  const questions: PropertiesMetaType[] = [
    {
      label: 'clientAvgSpendLastThreeMonths',
      path: 'clientAvgSpendLastThreeMonths',
      formatter: currencyFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'clientAvgCPCLastThreeMonths',
      path: 'clientAvgCPCLastThreeMonths',
      formatter: currencyFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'clientAvgCTRLastThreeMonths',
      path: 'clientAvgCTRLastThreeMonths',
      formatter: percentageFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'improvementPotential',
      path: 'improvementPotential',
      formatter: percentageFormatter(0),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },

    {
      label: 'mainChallenges',
      path: 'mainChallenges',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'kpiSetWithClient',
      path: 'kpiSetWithClient',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'implementationNeeded',
      path: 'implementationNeeded',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'currentAccountManager',
      path: 'currentAccountManager',
      formatter: labelValueFormatter(ACCOUNT_MANAGER_LABELS_LOOKUP),
      group: PROPS_GROUPS.OPEN_QUESTIONS,
    },
    {
      label: 'issuesWithProductOrService',
      path: 'issuesWithProductOrService',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
  ];

  return questions;
};

export default getProjectExistingMarketingEvaluationReportV3DetailsQuestions;
