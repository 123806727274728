import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { combineReducers, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-toastify/dist/ReactToastify.css';

import '../i18n';
import Routes from './Routes';
import Dashboard from './cpanel/Dashboard';
import { config } from '../config';
import EnsureLatestCpanelVersion from '../logic/EnsureLatestCpanelVersion';
import UnexpectedErrorPage from './cpanel/UnexpectedErrorPage';
import getApolloClient from '../app/Apollo';

import { theme } from '../../fe_common/client/app/theme';
import { Environment } from '../../fe_common/client/app/types';
import { ActingAccountProvider } from '../../fe_common/client/context/AccountContext';

const appReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    quickSearchSelectedIndex: 0,
  },
  action,
) => {
  // Check to see if the reducer cares about this action
  if (action.type === 'quickSearchSelectedIndex') {
    // If so, make a copy of `state`
    return {
      ...state,
      // and update the copy with the new value
      quickSearchSelectedIndex: action.payload,
    };
  }
  // otherwise return the existing state unchanged
  return state;
};

const rootReducer = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer,
  app: appReducer,
});

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const muiThemeV1 = createTheme(theme);

class Root extends Component {
  constructor(props) {
    super(props);

    this.enableVersionCheck = config.env !== Environment.DORKY;
  }

  render() {
    // Force HTTPS connection, will throw CORS errors if trying to connect with HTTP.
    if (!window.location.host.includes('rocks') && window.location.protocol !== 'https:') {
      window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
      return 'Refreshing with HTTPS connection';
    } else {
      return (
        <ApolloProvider client={getApolloClient()}>
          <Provider store={store}>
            <BrowserRouter>
              <MuiThemeProvider theme={muiThemeV1}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ActingAccountProvider>
                    <Sentry.ErrorBoundary fallback={UnexpectedErrorPage}>
                      <>
                        <CssBaseline />
                        <ToastContainer autoClose={15000} position={toast.POSITION.TOP_RIGHT} />
                        <Dashboard>
                          <Routes />
                        </Dashboard>
                      </>
                      {this.enableVersionCheck && <EnsureLatestCpanelVersion />}
                    </Sentry.ErrorBoundary>
                  </ActingAccountProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </BrowserRouter>
          </Provider>
        </ApolloProvider>
      );
    }
  }
}

export default Root;
