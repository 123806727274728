import React, { FC } from 'react';
import { NonPpcPackage } from '@mayple/types';

import { PROPS_GROUPS } from './index';

import { PropertiesMetaType } from '../../../../../../fe_common/client/components/display/PropertiesList';
import RichTextViewer from '../../../../../../fe_common/client/components/common/RichTextViewer';
import {
  numberOutOfNumberFormatter,
  percentageFormatter,
  periodInUnitsFormatter,
} from '../../../../../../fe_common/client/services/ExportToCSV/formatters';
import { TextDisplay } from '../../../../../../fe_common/client/components/display';

type UpsellPotentialCustomComponentProps = {
  value: any;
};

const UpsellPotentialCustomComponent: FC<UpsellPotentialCustomComponentProps> = (props) => {
  const { value } = props;
  const additionalServices = value?.additionalServices || [];

  return (
    <>
      {additionalServices.map((p: NonPpcPackage) => (
        <TextDisplay key={p?.uuid}>
          {p?.serviceType} - {p?.flavor}
        </TextDisplay>
      ))}
    </>
  );
};

const getProjectExistingMarketingEvaluationReportV2DetailsQuestions = () => {
  const questions: PropertiesMetaType[] = [
    {
      label: 'duration',
      path: 'duration',
      formatter: periodInUnitsFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'improvementPotential',
      path: 'improvementPotential',
      formatter: percentageFormatter(0),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },
    {
      label: 'overallAccountHealthScore',
      path: 'overallAccountHealthScore',
      formatter: numberOutOfNumberFormatter(),
      group: PROPS_GROUPS.SCORE_QUESTIONS,
    },

    {
      label: 'biggestGap',
      path: 'biggestGap',
      group: PROPS_GROUPS.OPEN_QUESTIONS,
      customComponent: RichTextViewer,
      layout: 'vertical',
    },
    {
      label: 'spotlightChapter',
      path: 'spotlightChapter',
      // formatter: spotlightChapterFormatter,
      group: PROPS_GROUPS.OPEN_QUESTIONS,
    },
    {
      label: 'upsellPotential',
      path: 'upsellPotential',
      customComponent: UpsellPotentialCustomComponent,
      group: PROPS_GROUPS.OPEN_QUESTIONS,
    },
  ];

  return questions;
};

export default getProjectExistingMarketingEvaluationReportV2DetailsQuestions;
